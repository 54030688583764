import pako from 'pako';

export class Compression {

     static parseQueryString(queryString) {
        // クエリ文字列の先頭の '?' を削除
        const query = queryString.startsWith('?') ? queryString.slice(1) : queryString;
    
        // Mapオブジェクトを作成
        const params = new Map();
    
        // パラメータを '&' で分割して key-value ペアに分割
        const pairs = query.split('&');
        pairs.forEach(pair => {
             var ss = pair.split('=');
             
            // Mapに格納。必要ならデコードせずにそのまま格納
           try{
            params.set(ss.shift(), ss.join('=') );
           }catch (e){ console.error(e); }
        });
       
        return params;
    }
    // GZIP圧縮
    static compressWithPako(input) {
        const encoder = new TextEncoder();
        const data = encoder.encode(input);
        const compressed = pako.gzip(data);
        return compressed;
    }

    // GZIP解凍
    static decompressWithPako(compressed) {
        const decompressed = pako.ungzip(compressed);
        const decoder = new TextDecoder();
        return decoder.decode(decompressed);
    }

    static async compressString(input) {
        let compressed;
        if ('CompressionStream' in window) {
            // Compression Streams APIを使う
            const encoder = new TextEncoder();
            const data = encoder.encode(input);

            const compressedStream = new CompressionStream('gzip');
            const writer = compressedStream.writable.getWriter();
            writer.write(data);
            writer.close();

            const compressedChunks = [];
            const reader = compressedStream.readable.getReader();
            let done, value;

            while (!({ done, value } = await reader.read()).done) {
                compressedChunks.push(value);
            }

            compressed = new Uint8Array(compressedChunks.reduce((acc, chunk) => acc.concat(Array.from(chunk)), []));
        } else {
            // Pakoにフォールバック
            compressed = Compression.compressWithPako(input);
        }

        // Base64エンコードとURLセーフな形式に変換
        let base64String = btoa(String.fromCharCode(...compressed));
        return base64String.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    }

    static async decompressString(encoded) {
        // URLセーフBase64を元の形式に戻す
        let base64String = encoded.replace(/-/g, '+').replace(/_/g, '/');
        while (base64String.length % 4) {
            base64String += '=';
        }

        const compressedArray = Uint8Array.from(atob(base64String), c => c.charCodeAt(0));

        if ('DecompressionStream' in window) {
            // Compression Streams APIを使う
            const decompressedStream = new DecompressionStream('gzip');
            const writer = decompressedStream.writable.getWriter();
            writer.write(compressedArray);
            writer.close();

            const decompressedChunks = [];
            const reader = decompressedStream.readable.getReader();
            let done, value;

            while (!({ done, value } = await reader.read()).done) {
                decompressedChunks.push(value);
            }

            const decompressedArray = new Uint8Array(decompressedChunks.reduce((acc, chunk) => acc.concat(Array.from(chunk)), []));
            const decoder = new TextDecoder();
            const decoded=decoder.decode(decompressedArray);
           
            return decoded;
        } else {
            // Pakoにフォールバック
            return Compression.decompressWithPako(compressedArray);
        }
    }
}
