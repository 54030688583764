<template>
<div class="editcss-area" v-show="params.show">

    <link rel="stylesheet" :ref="editcss" :href="cssURL" />
    
    <h5 >CSSの編集 
      <b v-on:click="closeEditor" style=" cursor: pointer; float:right;">X</b>
    </h5> 
   
  
    <select @change="navigateToPath" style="margin-bottom:1%;" class="form-control" v-model="editingPath" >
     <option  v-for="option in pathOptions" :value="option.value" v-bind:key="option.value">
    {{ option.text }}
  </option>

    </select>
       <MonacoEditor 
       :key="editorKey"
       v-model="editingCSS"
       style="width:100%; height:100px;"
        class="editor" 
        ref="editor"
        :options="option"
        language="css" 
        @change="editorDidChange"
       >
       </MonacoEditor>
    
    
    <button style="margin:1%;" class="btn btn-dark" v-on:click="showCSS"> 確認 </button> 
       <button style="margin:1%; float:right;" class="btn btn-success" v-on:click="saveCSS"> 保存 </button>
  

</div>

</template>

<script >
import { defineComponent } from "vue";
import MonacoEditor from 'monaco-editor-vue3'

export default defineComponent({
  name: "CSS",
  components:  { MonacoEditor } ,
  props: { params: Object  } ,
    data() { return {
        editorKey: 0,
        option : {"value":this.editingCSS },
        cssURL: "test.css",
        editingCSS : "",
        editingPath : "",
        pathOptions : [ /* { text: 'ダッシュボード全体', value: '*' } */ ]
       }
      },
    
      beforeMount  (){       
       
       this.pathOptions = [   { 
          text: 'ダッシュボード全体', 
          value: '*',
          css : (this.$parent.dashboard.css)? this.$parent.dashboard.css : ""  

         } ];
       
      if ( this.$parent.dashboard.menu  ){
        for ( let i=0 ; i < this.$parent.dashboard.menu.length; i ++ )
        { 
          if ( this.$parent.dashboard.menu[i] ){

            this.pathOptions.push (
              {
                 text : this.$parent.dashboard.menu[i]["menu-title"],
                 value : this.$parent.dashboard.menu[i]["path"],
                 css : this.$parent.dashboard.menu[i].css ? this.$parent.dashboard.menu[i].css : ""
              }
            )
          }
        }
      }
      
      for ( let i = 0 ; i < this.pathOptions.length ; i++ ){
        if (this.pathOptions[i].value == this.params.path ){
        
          this.editingPath =  this.pathOptions[i].value;
          this.editingCSS = this.pathOptions[i].css;
          this.editorDidChange(this.editingCSS);
          this.editorKey += 1; 
          this.showCSS ();
           
        }
      }
        
    },
      methods: {

        navigateToPath(event) {
      
      const selectedPath = event.target.value;
      
        for ( let i = 0 ; i < this.pathOptions.length ; i++ ){
        if (this.pathOptions[i].value == selectedPath ){
          this.editingPath =  this.pathOptions[i].value;
          this.editingCSS = this.pathOptions[i].css;
          this.editorDidChange(this.editingCSS);
          this.editorKey += 1; 
          this.showCSS ();
           
        }
      }

        this.$forceUpdate();
        if (selectedPath != "*"){

       this.$router.push( 
       { path: selectedPath, query: { edit:"css" } });
      }else {

         this.$emit('remove-css');
      } 

    },

      editorDidChange(text){     
            this.editingCSS  = text; 
            this.option.value = text;
        }, 
        closeEditor(){
           this.$parent.editCSSParams.show = false ;
        },
        async saveCSS ( ){
        
          for ( let i = 0 ; i < this.pathOptions.length ; i++ ){
            if (this.pathOptions[i].value == this.editingPath ){
              this.pathOptions[i].css = this.editingCSS;
              if (this.editingPath=="*"){ 

                this.$parent.dashboard.css = this.editingCSS;
                this.$parent .css =this.cssURL;
              } 
              else {

                if ( this.$parent.dashboard.menu  ){
                  for ( let i=0 ; i < this.$parent.dashboard.menu.length; i ++ )
                  { 
                      if ( this.$parent.dashboard.menu[i] ){
                        if ( this.editingPath == this.$parent.dashboard.menu[i].path ){

                          this.$parent.dashboard.menu[i].css = this.editingCSS;

                        }
                    }
                  }
                } 
              }
               await this.saveDashboard();
            }
          }
        },
        showCSS (){
          
          const base64String = Buffer.from(this.editingCSS, 'utf-8').toString('base64');
          // Base64 URLを生成
          this.cssURL = `data:text/css;base64,${base64String}`;
          
        },
        async saveDashboard ( ){
    
           try{
            
            this.showCSS() ;
            const option = {}  ;
            
            option.method = "PUT";
            option.body =  JSON.stringify(this.$parent.dashboard) ;
            option.header =  { "Content-Type": "application/json"};

            let res = await fetch (  process.env.VUE_APP_APP_API_V4_URL + 'dashboard/setting' ,
            option );      

           // let d = await res.json();  
           if ( res.ok  ){
       
              
              this.$emit('show-flash',{ "message":"設定しました。","type": "success"});     

              const url = String(window.location.href).replace("/pwa/", "/pwa/#/");
              window.location.href = url;
                }    
            
          } catch (e){
         
              console.log(e);
               this.$emit('show-flash',{ "message":"設定に失敗しました。","type": "warning"});  
         } 
          
      },
    }
    
    });
</script>
<style>

.editcss-area {
    background: lightgray;
    position: fixed;
    left: 0 ;
    bottom: 0;
    width: 100%;
    height: 260px;
    padding  : 1%;
    padding-left: 3%;
    padding-right: 3%;
}
</style>