
import { defineComponent } from "vue";
import { faPaperPlane, faMicrophone} from "@fortawesome/free-solid-svg-icons";
export default defineComponent({
props: {
    },
    data() { return {
        isLoaded : true ,
        messages:[],
        windowClass : " offset-md-6 col-md-6 offset-0 col-12",
        messagesCss : { "height":"50vh"},
        messagesScrollCss : { "height":"78%"},
        windowCss : {} ,
        voiceInputCss : {} ,
        userMessage:{role:"user",content:""},
          faPaperPlane :faPaperPlane,
          faMicrophone : faMicrophone ,
          voiceInputStarted : false ,
      showChat : "hide",
      show : "hide" ,
    recognition : undefined
    } },
     async  beforeMount  (this:any){        
    
     if(window.parent !== window && !(window as any ).isCrossOrigin ){  
          return;
        }
        try {
           const exists = await ( await fetch(  process.env.VUE_APP_APP_API_V4_URL  + "openai/apikey") ) .json(); 
           if (! exists.exists ) { 
  
              this.showChat="hide";
           } else {  
            this.showChat="";
           
            }
       } catch (e){  console.log(e ); this.showChat="hide";  } 
       
        try{ 
            
            const sr:any = ( window as  any ).webkitSpeechRecognition ||  ( window  as any ).SpeechRecognition;
            this.recognition = new sr();
            this.recognition.lang = 'ja-JP';
            this.recognition.interimResults = true;
            this.recognition.continuous = true;
            this.recognition.onresult = (event:any) => {
             let interimTranscript = ''; // 暫定(灰色)の認識結果
            for (let i = event.resultIndex; i < event.results.length; i++) {
                 let transcript :any = event.results[i][0].transcript;
            if (event.results[i].isFinal) {
                     this.userMessage.content += transcript;
                     
                }
            }
   } 
   }catch(e){ console.log(e);}
       
       
    },
      methods: {
          voiceInput(this:any){
              if ( this.voiceInputStarted){
                  this. recognition.stop();
                  this.voiceInputStarted =false;
                  this.voiceInputCss= {};
                  return;
              }
              this.voiceInputStarted = true;
               this.voiceInputCss= {"color":"yellowgreen"};
             this.recognition.start();
          },
      expansionWindow(this:any){
          
          
          if ( this.windowClass ===" col-12" ){
                
                this.windowClass=" offset-md-6 col-md-6 offset-0 col-12 ";   
                this.windowCss={ };
                this.messagesCss = {  "height":"50vh"};
                this.messagesScrollCss = { "height":"78%"};
          } else {
              
                this.windowClass =" col-12"  ;
                this.windowCss={ "left":0 , "height":"100vh", "top": 0  };
                this.messagesCss = { "height":"80vh"};
                this.messagesScrollCss = { "height":"100%"};
                this.show = "";  
          }
          
          const elms:any =  ( window as any ).document.getElementsByClassName("iframe_graph");
        // var cdt = new Date().getTime();

          for (  const i in elms ){
              
              try{ 
                const src = elms[ i ].src;
                
                elms[ i ].src =  src +"&c";
              }catch (e:any) { console.log(e);}
          }
          
      },
   async keyEnterShift(this:any ) { await this.chat(); },
        async chat(this:any){
         this.isLoaded=false;
        try {
          const el = this.$refs["comments"] as HTMLElement;
          
           this.messages.push( JSON.parse( JSON.stringify(this.userMessage)) );   
          this.userMessage.content = "";
          setTimeout(() => {  el?.scrollTo(0, el?.scrollHeight); }, 50);
         
          // console.log( JSON.stringify({messages: this.messages} ) );
           const res =  ( await fetch(  process.env.VUE_APP_APP_API_V4_URL  + "openai/chatgpt/chat" ,     { method: "POST",   headers: { "Content-Type": "application/json" },body : JSON.stringify( {messages: this.messages} ) } )  ) 
           
           res.json().then( async (json:any)=>{
            
              console.log( json.message);
              var content = json.message.content.replace(/\r?\n/g," ");
           const apiparams :any = content.match(/\[api\](.*?)\[\/api\]/g);

            for (  const key   in apiparams ){ 
                const param = apiparams[key].replace("[api]","").replace("[/api]","");
                const p:any =param.split(',');
                 await new Promise<void>((resolve, reject) => {
                     console.log(p);
                    this.$emit("fetch-data", { "prefetch": {"sensor":{"async":true,"periodofday": p[2]+","+p[3],"granularity":[p[0]],"sensors":[p[1]]} }  ,  "done" : ()=>{   resolve( ); } });
                 }) ; 
                 content = content.replace( apiparams[key],"");
            }

           const sqlparams :any = content.match( /\[sql\](.*?)\[\/sql\]/g);
           for (  const key   in sqlparams ){ 
       
                 try{
                 var  param =   sqlparams[key];
                 const p:any =param.split(',');
                 if ( param.includes("[line]") ){
                    param = param.replace("[line]" , "" ).replace("[/line]" , "" ).replace("[sql]","").replace("[/sql]","").replaceAll ( '%','%25' ).replaceAll ( '+','%2B' ) ;
                   
                     content = content.replace( /\[sql\](.*?)\[\/sql\]/g,'<iframe class="iframe_graph" style="width: 100%; height: 40vh;" src="/pwa/#/chart/line/?sq=0&q='+param+'">');
                }
                if ( param.includes("[table]") ){
                    param = param.replace("[table]" , "" ).replace("[/table]" , "" ).replace("[sql]","").replace("[/sql]","").replaceAll ( '%','%25' ).replaceAll ( '+','%2B' ) ;
                      content =content.replace(  /\[sql\](.*?)\[\/sql\]/g , '<iframe  class="iframe_graph"  style="width: 100%; height: 40vh;" src="/pwa/#/chart/metric/?sq=0&q='+param+'" >' );     
                 }
                 }catch (e:any){ console.log(e); }
          }  
            
          this.messages.push({ role : json.message.role , content : content.replace('```sql',"```").replace('```',"<pre>").replace('```',"</pre>") });
            
              setTimeout(() => {  el?.scrollTo(0, el?.scrollHeight); }, 50);
            
           } );
           
       } catch (e){  
                this.isLoaded=true;
       console.log(e ); } 
               this.isLoaded=true;
        },
    openChat ( this:any ){
        
       if ( this.show === "hide" ){
           
           this.showChat="fadeUp";
           this.show="" ;
       }else { 
       this.windowClass=" offset-md-6 col-md-6 offset-0 col-12 ";   
                    this.windowCss={ };
                    this.messagesCss = {  "height":"50vh"};
                    this.messagesScrollCss = { "height":"78%"};
          this.show ="hide";
           this.showChat="";
        } 
    }
    }
    
    });
