const moment = require('moment-timezone');
 
import { db } from '../app/indexeddb'; 

const funs:any =[];

const tZOffsetSec=  -1 * (new Date().getTimezoneOffset() * 60 ) ;

let lastsearchTimes:any ={};
let loadedReqs:any ={};
const displayedTime = moment() ;

const requestChunkSize = 8;
const nodeIdChunkSize = 3;

export function fetchSensorData ( 

	urlstr:string ,option={} as any,
	withCache:boolean,
	fun:any=false
){

	
	const conf =  {  "cache" : "no-store" } as any;
   
/*	if(!withCache){
		
		conf.method  = "POST";
		conf.headers = {"Content-Type": "application/json"} ;
		conf.body = JSON.stringify(option );
	}
*/

	const newCaches:any = [];
		
	const cacheSucceed = async ()=>{
		let c =newCaches.shift(); 
		while (c){

			await (db as any ).sensorDataCache.put(c );
			c =newCaches.shift(); 
		}
		
	}
	return async ()=>{
	

	const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let url:any =  undefined;
    if (urlstr.indexOf('https://') !== -1){
   
        url =  new URL( urlstr );
    } else{
        
	    const urls =  location.href.split('/') ;
        url = new URL( urls[0]+'//'+urls[2]+ urlstr);
    } 
   
    const params = url.searchParams;

		for (const key of Object.keys(option)) {
      
	  		params.append(key, option[key]);
    	}
   
	params.set("tz",tz);

    let today = moment( moment().tz(tz).format('YYYY-MM-DD') );
    const tomorrow =moment(moment().add(1,'days').tz(tz).format('YYYY-MM-DD'));
   
    const startstr = params.get('start');
    let start =moment ( startstr );
	if ( today<start ){
		today =start.add(1, 'seconds');
	}

    const endstr = params.get('end') ;
    let end = moment(endstr);

    if(option.latest){

		start =moment ( today );
		end = moment( tomorrow );
	}
   
   // let waitMS:any = 1;

 	if ( tomorrow < start || 
       tomorrow < end ||
       today > tomorrow
     ){

   	     throw ' invalid data detected .  ';
    }

    let data:any[] = [];

    if ( ( start.isSame( today ) && end.isSame ( tomorrow ) ) ||
     ( start.isBefore( today ) && end.isSame ( tomorrow ) )   ) { 
	    
		/* キャッシュなし*/
		
		if(lastsearchTimes[url.toString()]===undefined){

			params.set("start",today.format('YYYY-MM-DD HH:mm:ss'));
		}else {
			
			params.set("start",lastsearchTimes[url.toString()].format('YYYY-MM-DD HH:mm:ss'));
		}
		lastsearchTimes[url.toString()]= moment().tz(tz);

        params.set("end",tomorrow.format('YYYY-MM-DD'));
	  //  await new Promise(resolve => setTimeout(resolve, 3));


        let res = await fetch( url.toString() ,conf);
 	
	  if( 200 != res.status){  return  { error : res.status , data: data}; }
 	  
 	  while(!res.ok){
		
		// await new Promise(resolve => setTimeout(resolve, 3));

   	     res = await fetch( url.toString() ,conf);   
 	  }
 	    if( 200 != res.status){  return  { error : res.status , data: data}; }
 	  	
 	   if( res.ok){ 
 	  	  	let json:any = await res.json() 
      		let token = json.continuationToken;
      		
			if(false !==fun){ await fun(json.data); }else{
				
				data = data.concat (json.data);
			}
			
			
      		if(token)
      		while (  token != "" ){
      		       
      		 //   await delay(3);
			    params.set("continuationToken",token);
				
			 //  await new Promise(resolve => setTimeout(resolve, waitMS));


      			res = await fetch( url.toString() , conf );
				
      		    while(!res.ok){
   	            
			  	// await new Promise(resolve => setTimeout(resolve, waitMS));
	
   	             res = await fetch( url.toString() ,conf);   
 	           }
 	  
      				if( res.ok ){
      					json =await res.json();
      					if (json.continuationToken == undefined){
							token = "";
						}else {
						token = json.continuationToken;
						}

      				    if(false !==fun){ await fun(json.data); }else {
						data = data.concat (json.data);
						}
 
      				} else{
      				    break;
      				}
      		}
 		}  
 		if(start .isBefore( today) ){
 	        end.add('-1','days');
 	     }
 	     
 		}
 
   params.set("continuationToken","");
   //only-if-cached
  
   if (  start.isBefore( today ) && end.isBefore( tomorrow)    ) { 
   
      // キャッシュあり 
   //params.set("continuationToken","");
      for (;;){
		
		
	  params.set("end",end.format('YYYY-MM-DD'));
      end = end.add('-1','days');

	  if ( start.format('YYYY-MM-DD') == end.format('YYYY-MM-DD')){
		end =  start;
	  }
	  

      params.set("start",end.format('YYYY-MM-DD HH:mm:ss') );
     
      let tempdata:any[] = [];
      let cache_setting:any =  "force-cache";
      let token = "";
	  if(loadedReqs[url.toString()]){
		
		continue;
		}
	

	let cache:any = false ;
	if (withCache){
    	 cache = await (db as any ).sensorDataCache.get({url: url.toString()  });
	}

	 let res:any = {ok:false};
	 
      if(cache){ 
		
		    tempdata = tempdata.concat (cache.data.data );
						
			if ( cache.data.continuationToken == undefined){
		
				token = "";    	
			} else { 
			
				token = cache.data.continuationToken;
			}
		
      } else {

		     
			
		    res = await fetch(  url.toString() ,conf);
             if( 200 != res.status){  return  { error : res.status , data: data}; }
            while(!res.ok){
				
				// await new Promise(resolve => setTimeout(resolve, waitMS));
   	             res = await fetch( url.toString() ,conf);   
 	        }
 	        if( 200 != res.status){  return  { error : res.status , data: data}; }
            if( res.ok){ 
 	 
 	  		const json:any = await res.json() 
      	    
			if (withCache){
      			newCaches.push({ url:url.toString() , data:  { continuationToken :  json.continuationToken }} );
			}
	  
			if(json.continuationToken)
			   { token = json.continuationToken; }else {
				token ="";
			   }
      		
			 
			  if ( token == "expired" ){
				 
				   tempdata = [];
				   params.set("continuationToken","");   
				   token = ""; 
					cache_setting = "reload";  		    
			   } else{  
				 params.set("continuationToken",token);
				 
				 tempdata = tempdata.concat (json.data);
				
			   }
      	  }       
 	  }

	   if(false !==fun){ 
		

		await fun(tempdata ); 
	    await cacheSucceed();
	
	}else{
		data = data.concat (tempdata);
		  }
	 

      	if( res.ok || cache ){ 
 	  		
 
      		while (token != "" ){
		//    await delay(3);
		      
		     
		     const cache = await (db as any ).sensorDataCache.get({url: url.toString()  });
			  if ( withCache && cache && cache.data){

				     if(  cache.data.continuationToken == undefined) {
      			     	
						params.set("continuationToken","");
						token = "";
					 }else {
						token = cache.data.continuationToken;
						params.set("continuationToken",token);
						
					 }

      			     tempdata = tempdata.concat (cache.data.data );
      			  
      			  } else{

					 // await new Promise(resolve => setTimeout(resolve, waitMS));
					 const c = JSON.parse (JSON.stringify (conf));
					 
					
					 c . cache = cache_setting;
          			 res = await fetch(  url.toString() ,c );
      			     while(!res.ok){
						// await new Promise(resolve => setTimeout(resolve, waitMS));
   	                     res = await fetch( url.toString() ,conf );   
 	                  }
      				
      				if( res.ok){
      				    
						
      					const json =await res.json();
						
						if(withCache){

							newCaches.push( { url :url.toString() , data: { continuationToken : json.continuationToken } });
							 
						}
						if (json.continuationToken){
      						token = json.continuationToken;
						} else { token = ""; }

						 if(token)
      				     if ( token == "expired" ){
      				        
      			              tempdata = [];
      			              params.set("continuationToken","");   
      			              token = ""; 
      			               cache_setting = "reload";  		    
      			          } else{  
      				        params.set("continuationToken",token);
      					    tempdata = tempdata.concat (json.data);
      					  }
    
						  
      				
						}
      			  } 
      			
      			}
      		
				if(false !==fun){
	//				await (db as any ).sensorDataCache.put( );
	
					await fun(tempdata); 
					await cacheSucceed();
				}else{

					data = data.concat (tempdata);
				}

 			} 
			 


 		    if(start >= end ){
				break;
			
			}
			loadedReqs[url.toString()]= true;
 		}			
   }


	
   return { status :200 ,  data :data} ;
	}
}




export async function setFetchLatestDataAndloadToSqlite3 (  
	node_id : any
 ){


		try {

			let withCache = true;
			const param = { "latest":"true" } as any;
			const urls = [];

			if (Array.isArray(node_id)){
	
				const ids =[];
				const copy =  JSON.parse (JSON.stringify (node_id));
				while(copy.length) ids.push(copy.splice(0, nodeIdChunkSize));
				
				withCache = false;
				for (let i=0 ; i <ids.length ; i++ ){
					if (ids[i]){
						urls.push (process.env.VUE_APP_APP_API_V4_URL +"sensors/data/?pkeys=" + ids[i].join(',')) ;
					}
				}

				
			}else {

				urls.push ( process.env.VUE_APP_APP_API_V4_URL +"sensors/data/"+ node_id  );
			}
	
			for ( let i=0 ; i < urls.length ; i++ ){

			const f={ db:[]} as any; 
			f.fetch = 
			 fetchSensorData(urls[i], param , withCache ,async (data:any)=>{
				
				f.db.push ( async ()=>{ 
				const Sql = await  ( window as any ).getSql();
				if(data){
	
					const sqls:any =[];
					const d = data.reverse(); 
					for (const i in d ){
						const value = d[i];
						if ( !value || !value.datetime) continue;
						  
						 // sqls.push( " INSERT OR IGNORE INTO latest_data (sensed_at , datetime, data1, data2, data3, cdata ,battery, alive, sensor_type,sensor_id) VALUES (datetime( "+tZOffsetSec+" + ( "+ value.datetime + "/1000) ,'unixepoch') , "+value.datetime+", '"+value.data1+"','"+value.data2+"','"+value.data3+"','"+value.cdata+"',"+value.battery+ ",'"+value.alive+"','"+value.sensor_type+"' ,'"+value.node_id+"'); " );
						 if ( !value.data1 ){  value.data1  = value.str_data1 ; }
						if ( !value.data2 ){  value.data2  = value.str_data2 ; }
						if ( !value.data3 ){  value.data3  = value.str_data3 ; } 
						
						 sqls.push(
							"REPLACE INTO latest_data (sensed_at, datetime, data1, data2, data3, cdata, battery, alive, sensor_type, sensor_id) VALUES (" +
							"datetime(" + tZOffsetSec + " + (" + value.datetime + " / 1000), 'unixepoch'), " +
							value.datetime + ", '" + value.data1 + "', '" + value.data2 + "', '" + value.data3 + "', '" + value.cdata + "', " +
							value.battery + ", '" + value.alive + "', '" + value.sensor_type + "', '" + value.node_id + "');"
						  );
						  
						}
								const newArray = sqls.reduce((acc:any, val:any, i:any) => (i % 10000 ? acc[acc.length - 1] += val : acc.push(val), acc), []);
	  
					for (let j = 0; j < newArray.length; j++) {
						Sql.executeAsyncQueue( "BEGIN TRANSACTION;"+newArray[j]+"COMMIT" , (res:any)=>{});
						await Sql.executeAsync();
					}
					
				}
				});
				});
				funs.push(f);
				
			}

		}catch (error) {
			console.error(error);
	} 
			
	}


	async function checkData(table :string ,pkey:string ,start :any , end:any ){

		const Sql = await ( window as any ).getSql();

		return new Promise ( (r)=>{ 
			
			const id_and_type = pkey.split('-');

			let sql = "";
			if ( id_and_type.length ==2 ){
		     sql ="select max(sensed_at) as start, min(sensed_at) as min from "+table+" where sensor_id='"+ id_and_type[1] + "'";
			 sql += " and sensor_type ='"+id_and_type[0]+ "'";
			
			}
			else {
				sql ="select max(sensed_at) as start , min(sensed_at) as end from "+table+" where sensor_id='"+ id_and_type[0] + "'";
			}

			if(start) {

				sql += " and sensed_at >= '" + start + "'";  
			}
			if (end){

				sql += " and sensed_at <= '" + end + "'";  

			}
			sql + " ;";
			Sql.execute( sql , ( res:any)=>{
					r(res);
			});
		});
	}

export async function setFetchRawDataAndloadToSqlite3 (  
	pkey : any,
	start :any ,
	end :any ){

		try {

			// const  url = process.env.VUE_APP_APP_API_V4_URL +"sensors/data/"+ node_id + '?start='  + start.format('YYYY-MM-DD') + '&end=' + end.format('YYYY-MM-DD');
		
			let withCache = true;
			const urls= [];
			const param = {} as any;
	
			let startStr= start.format("YYYY-MM-DD");
		const endStr= end.format("YYYY-MM-DD");

		if(startStr == moment().format('YYYY-MM-DD') ){ 
		if( Array.isArray(pkey) ){
	
			for ( let i = 0; i < pkey.length ; i++ ){
				const res:any  =await checkData("raw_data" ,pkey[i] ,startStr , endStr );
				
				const nstartStr = res[0].values[0][0];
				if (undefined != nstartStr){

					const nstart =moment(new Date(nstartStr));
					if (start < nstart){
						
						startStr = nstartStr;
						
						start = nstart;
					}
				}

			}
		}else {
			
			const res:any  =await checkData("raw_data" ,pkey ,startStr , endStr );
			
			let tmpstart = false;
			const nstartStr = res[0].values[0][0];
			if ( nstartStr ){
				const nstart =moment(new Date(nstartStr));
				if (start < nstart){
				
					if(tmpstart===false){
						
						startStr = nstartStr;
						start = nstart;
						tmpstart = nstart;
					}else if ( start < tmpstart  ) {

						startStr = nstartStr;
						start = nstart;
						tmpstart = nstart;
					
					}
				}
			}
		}

	
		}

		if (Array.isArray(pkey)){

				const ids =[];
				// withCache = false ;
				withCache = true;
				const copy = JSON.parse (JSON.stringify (pkey));
	
				while(copy.length) ids.push(copy.splice(0, nodeIdChunkSize));
				
				// url = process.env.VUE_APP_APP_API_V4_URL +"sensors/data/?pkeys="+ node_id.join(',') + '&start='  + start.format('YYYY-MM-DD') + '&end=' + end.format('YYYY-MM-DD');
				
				for (let i=0 ; i <ids.length ; i++ ){

					if (ids[i]){
					urls.push (process.env.VUE_APP_APP_API_V4_URL +"sensors/data/?pkeys="+ ids[i].join(",") +"&start=" + startStr + '&end=' + endStr);
			
					}
				}

				//start.format('YYYY-MM-DD')
				
			}else {
				
				urls.push(process.env.VUE_APP_APP_API_V4_URL +"sensors/data/"+ pkey + '?start=' + startStr + '&end=' + endStr ); 
			
			}
	
			for ( let i=0 ; i < urls.length ; i++ ){

			const f={ db:[]} as any; 
			f.fetch = 
			 fetchSensorData(urls[i], param ,withCache, async (data:any
			 )=>{
				const Sql = await ( window as any ).getSql();

				f.db.push ( async ()=>{ 
				
				if(data){
	
					const sqls:any =[];
					const d = data.reverse(); 
					for (const i in d ){
						const value = d[i];
						if ( !value || !value.datetime) continue;
						  
						if ( !value.data1 ){  value.data1  = value.str_data1 ; }
						if ( !value.data2 ){  value.data2  = value.str_data2 ; }
						if ( !value.data3 ){  value.data3  = value.str_data3 ; } 
						  sqls.push( " INSERT OR IGNORE INTO raw_data (sensed_at , datetime, data1, data2, data3, cdata ,battery, alive, sensor_type,sensor_id) VALUES (datetime( "+tZOffsetSec+" + ( "+ value.datetime + "/1000) ,'unixepoch') , "+value.datetime+", '"+value.data1+"','"+value.data2+"','"+value.data3+"','"+value.cdata+"',"+value.battery+ ",'"+value.alive+"','"+value.sensor_type+"' ,'"+value.node_id+"'); " );
					}
								const newArray = sqls.reduce((acc:any, val:any, i:any) => (i % 10000 ? acc[acc.length - 1] += val : acc.push(val), acc), []);
	  
					for (let j = 0; j < newArray.length; j++) {
				Sql.executeAsyncQueue( "BEGIN TRANSACTION;"+newArray[j]+"COMMIT" , (res:any)=>{});
				await Sql.executeAsync();
					}
					
					
				}
			});

			});
			funs.push(f);
			}
		

		}catch (error) {
			console.error(error);
		} 
	}

	export async function setFetchFirstDataAndloadToSqlite3 (  
		pkey : any,
		start :any ,
		end :any ,
		selectedParticleSize:string,
		){

			try { 
		  

				let withCache = true;
				const urls= [];
				const param={};


				let startStr= start.format("YYYY-MM-DD");
		const endStr= end.format("YYYY-MM-DD");
		if(startStr == moment().format('YYYY-MM-DD') ){ 
		if( Array.isArray(pkey) ){

			for ( let i = 0; i < pkey.length ; i++ ){
			 
				const res:any  =await checkData("first_"+ selectedParticleSize ,pkey[i] ,startStr , endStr );
				
				const nstartStr = res[0].values[0][0];
				if (undefined != nstartStr){

					const nstart =moment(new Date(nstartStr));
					if (start < nstart){
						
						startStr = nstartStr;
						
						start = nstart;
					}
				}

			}
		}else {

			const res:any  =await checkData("first_"+ selectedParticleSize ,pkey ,startStr , endStr );
			
			let tmpstart = false;
			const nstartStr = res[0].values[0][0];
			if ( nstartStr ){
				const nstart =moment(new Date(nstartStr));
				if (start < nstart){
				
					if(tmpstart===false){
						
						startStr = nstartStr;
						start = nstart;
						tmpstart = nstart;
					}else if ( start < tmpstart  ) {

						startStr = nstartStr;
						start = nstart;
						tmpstart = nstart;
					
					}
				}
			}
		}
		}
				if (Array.isArray(pkey)){

					const ids =[];
					withCache = false ;
					const copy = JSON.parse (JSON.stringify (pkey));

				    while(copy.length) ids.push(copy.splice(0, nodeIdChunkSize));
				
					// url = process.env.VUE_APP_APP_API_V4_URL +"sensors/data/?pkeys="+ node_id.join(',') + '&start='  + start.format('YYYY-MM-DD') + '&end=' + end.format('YYYY-MM-DD');
					
					for (let i=0 ; i <ids.length ; i++ ){
						if (ids[i]){
					
							urls.push(process.env.VUE_APP_APP_API_V4_URL +"sensors/data/?pkeys="+ids[i].join(",") +"&start=" + startStr + '&end=' + endStr +"&first="+selectedParticleSize + (process.env.VUE_APP_API_CODE)!.replace('?','&'));
							
						}
					}
					
				} else {

					urls.push (process.env.VUE_APP_APP_API_V4_URL +"sensors/data/"+ pkey + '?start=' + startStr + '&end=' + endStr +"&first="+selectedParticleSize + (process.env.VUE_APP_API_CODE)!.replace('?','&'));			
				}

				for ( let i=0 ; i < urls.length ; i++ ){

		 
			const f={ db:[]} as any; 
			f.fetch = 
			 fetchSensorData(urls[i],param,withCache,async (data:any)=>{
				f.db.push ( async ()=>{ 

				const Sql = await  ( window as any ).getSql();
				if(data){
					const sqls:any =[];
					const d = data.reverse(); 
					for (const i in d ){
						const value = d[i];
						if ( !value || !value.datetime) continue;
						  
						if ( !value.data1 ){  value.data1  = value.str_data1 ; }
						if ( !value.data2 ){  value.data2  = value.str_data2 ; }
						if ( !value.data3 ){  value.data3  = value.str_data3 ; } 
						
						  sqls.push( " INSERT OR IGNORE INTO first_"+selectedParticleSize +" (sensed_at , datetime, data1, data2, data3, cdata ,battery, alive, sensor_type,sensor_id) VALUES ( datetime(  "+tZOffsetSec+" + ( "+ value.datetime + "/1000) ,'unixepoch') , "+value.datetime+", '"+value.data1+"','"+value.data2+"','"+value.data3+"','"+value.cdata+"',"+value.battery+ ",'"+value.alive+"','"+value.sensor_type+"' ,'"+value.node_id+"'); " );
					}

					const newArray = sqls.reduce((acc:any, val:any, i:any) => (i % 10000 ? acc[acc.length - 1] += val : acc.push(val), acc), []);
	
					for (let j = 0; j < newArray.length; j++) {
					Sql.executeAsyncQueue( "BEGIN TRANSACTION;"+newArray[j]+"COMMIT" , (res:any)=>{});
						await Sql.executeAsync();  
					}
					
						  
				}
			});
			});

			funs.push(f);
		 }
		}catch (error) {
			console.error(error);
	}
	}	

export async function setFetchAggregateDataAndloadToSqlite3 (  
		pkey : any,
		start :any ,
		end :any ,
		selectedParticleSize:string,
		){
	
	try { 

		let withCache = true;
		const urls= [];
		// const param = {} as any;

		let startStr= start.format("YYYY-MM-DD");
		const endStr= end.format("YYYY-MM-DD");
		if(startStr == moment().format('YYYY-MM-DD') ){ 
		if( Array.isArray(pkey) ){

			for ( let i = 0; i < pkey.length ; i++ ){
			 
				const res:any  =await checkData("aggregate_"+ selectedParticleSize ,pkey[i] ,startStr , endStr );
				
				const nstartStr = res[0].values[0][0];
				if (undefined != nstartStr){

					const nstart =moment(new Date(nstartStr));
					if (start < nstart){
						
						startStr = nstartStr;
						
						start = nstart;
					}
				}

			}
		}else {

			const res:any  =await checkData("aggregate_"+ selectedParticleSize ,pkey ,startStr , endStr );
			
			let tmpstart = false;
			const nstartStr = res[0].values[0][0];
			if ( nstartStr ){
				const nstart =moment(new Date(nstartStr));
				if (start < nstart){
				
					if(tmpstart===false){
						
						startStr = nstartStr;
						start = nstart;
						tmpstart = nstart;
					}else if ( start < tmpstart  ) {

						startStr = nstartStr;
						start = nstart;
						tmpstart = nstart;
					
					}
				}
			}
		}
		}

		if (Array.isArray(pkey)){

			const ids =[];
			withCache = false ;
			
			const copy = JSON.parse (JSON.stringify (pkey));
			
			while(copy.length) ids.push(copy.splice(0, nodeIdChunkSize));
				
			// url = process.env.VUE_APP_APP_API_V4_URL +"sensors/data/?pkeys="+ node_id.join(',') + '&start='  + start.format('YYYY-MM-DD') + '&end=' + end.format('YYYY-MM-DD');
			
			for (let i=0 ; i <ids.length ; i++ ){
				if (ids[i]){
			
					urls.push( process.env.VUE_APP_APP_API_V4_URL +"sensors/data/?pkeys="+ ids[i].join(",") +"&start="  + startStr + '&end=' + endStr+"&agg="+selectedParticleSize + (process.env.VUE_APP_API_CODE)!.replace('?','&'));
				}
			}
			
		}else {

	
			urls.push(process.env.VUE_APP_APP_API_V4_URL +"sensors/data/"+ pkey + '?start='  + startStr + '&end=' + endStr +"&agg="+selectedParticleSize + (process.env.VUE_APP_API_CODE)!.replace('?','&'));		
	
		}

	
	for ( let i=0 ; i < urls.length ; i++ ){

	const f={ db:[]} as any; 
  	f.fetch = fetchSensorData( urls[i] , {},withCache,async (data:any)=>{

    f.db.push ( async ()=>{ 

    const Sql = await  ( window as any ).getSql();
    if(data){

	 const d = data.reverse(); 
	 const sqls:any =[];
	 for (const i in d ){
	        
			const value = d[i];
			if ( !value || !value.datetime) continue;
			
			sqls.push("  INSERT OR IGNORE INTO aggregate_"+ selectedParticleSize +" ( sensed_at , datetime, median_data1, median_data2, median_data3, median_cdata, median_battery,avg_data1, avg_data2, avg_data3, avg_cdata, avg_battery ,max_data1, max_data2, max_data3, max_cdata, max_battery, min_data1, min_data2, min_data3, min_cdata, min_battery,sensor_type,sensor_id) VALUES ( datetime(  "+tZOffsetSec+" + ( "+ value.datetime + "/1000) ,'unixepoch') ,"+value.datetime+","+value.median_data1+","+value.median_data2+","+value.median_data3+","+value.median_cdata+","+value.median_battery+","+value.avg_data1+","+value.avg_data2+","+value.avg_data3+","+value.avg_cdata+","+value.avg_battery+","+value.max_data1+","+value.max_data2+","+value.max_data3+","+value.max_cdata+","+value.max_battery+","+value.min_data1+","+value.min_data2+","+value.min_data3+","+value.min_cdata+","+value.min_battery+",'"+value.sensor_type+"','"+value.node_id+"'); "
			);
			   
	 }
	 

	    const newArray = sqls.reduce((acc:any, val:any, i:any) => (i % 10000 ? acc[acc.length - 1] += val : acc.push(val), acc), []);

			for (let j = 0; j < newArray.length; j++) {
		   
			Sql.executeAsyncQueue( "BEGIN TRANSACTION;"+newArray[j]+"COMMIT" , (res:any)=>{});
			await Sql.executeAsync();
		}
			

	}
		});
	});

	funs.push(f);
	}
	}catch (error) {
		console.error(error);
	}
}

export async function  setFetchAndloadToSqlite3 (  
	node_id : any,
	start :any ,
	end :any ,
	selectedParticleSize:string,
	needFirstData :boolean  ,
	needAggregateData:boolean ,
	needRawData: boolean,
	needLatestData :boolean
	){

	
	const now = moment();

	if(!displayedTime.isSame(now,'day')){
            
		lastsearchTimes = {};
		loadedReqs={}
	}

	if(needLatestData  ){
		
		await setFetchLatestDataAndloadToSqlite3(node_id);
	}
    if(needRawData  ){
		
		await setFetchRawDataAndloadToSqlite3(node_id, start ,end );
	}
		
	if(needFirstData ){
		
		await setFetchFirstDataAndloadToSqlite3(node_id ,start ,end ,selectedParticleSize )
	}
		
	if(  needAggregateData ){
		
		await setFetchAggregateDataAndloadToSqlite3(node_id ,start ,end ,selectedParticleSize )
	}
	
}

export async function fetchAndloadToSqlite3Exec (){

	const chunkedArray =[];

	const fs = funs.concat(); 
    
	while(funs.length) chunkedArray.push(funs.splice(0, requestChunkSize));
	/* const chunkedArray = Array.from(
        {length: Math.ceil(funs.length / 5)}
        , (_, i) => funs.slice(i * 5, i * 5 + 5));
		  */

        const dbs =[];
        const waitMS = 200; // Math.floor(Math.random() * 300 ) ;			    
		for (const chunk of chunkedArray) {
			// ここで非同期関数を実行
		  await new Promise(resolve => setTimeout(resolve, waitMS));
		
			await Promise.all(chunk.map( (asyncFunc:any) => asyncFunc.fetch()));
			//console.log('One chunk completed');
		}
		for ( let i=0; i< fs.length;i++){
			
			dbs.push( fs[i].db );

		} 

		await Promise.all( dbs.flat().map( (asyncFunc:any) => asyncFunc()));
		
}