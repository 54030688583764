// Subject class in TypeScript
export class Subject {
   private observers: Observer[] = [];

   // Add an observer to the list
   subscribe(observer: Observer): void {
       this.observers.push(observer);
   }

   // Remove an observer from the list
   unsubscribe(observer: Observer): void {
       this.observers = this.observers.filter(sub => sub !== observer);
   }

   // New method to remove all observers
   unsubscribeAll(): void {
       this.observers = [];
   }

   // Notify all observers of a state change
   notify(data: any): void {
       this.observers.forEach(observer => observer.update(data));
   }
}

// Observer class in TypeScript
export class Observer {
   constructor(private name: string) {}

   update(data: any): void {
       console.log(`${this.name} received data: ${data}`);
   }
}
