
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import './registerServiceWorker';




import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
/* import { library } from "@fortawesome/fontawesome-svg-core"; */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import ECharts from 'vue-echarts' // refers to components/ECharts.vue in webpack

import 'echarts/lib/chart/bar'
import 'echarts/lib/component/tooltip'

import 'echarts-gl'

import OpenLayersMap from 'vue3-openlayers'
import 'vue3-openlayers/dist/vue3-openlayers.css'


createApp(App).component("font-awesome-icon", FontAwesomeIcon)
.component('v-chart', ECharts)

.use(store)
.use(OpenLayersMap)
.use(router).mount("#app");
