// ( window as any ).Sql = false;


export class Sql  {

   private _worker?:any ;
   public tableLodaded? : boolean;
   public _commandQueue ? : any =[];
   private _isExecuting? : boolean =false;
   public valiables? :any ={};
   private sqlprocess? :any = {};
   private count = 1;

   constructor() {


      try{
      if(( window as any ).parent.Sql){
       
         return ( window.parent as any ).Sql;
      }
      } catch ( e ){
         console.error(e);
      }
      this.tableLodaded = false;
      const { port1, port2 } = new MessageChannel();
   
      (window as any).SqliteWorker.postMessage('messagePort-'+(window as any).navigator.userAgent , [port2]);
      port1.addEventListener('message', (event :any ) => {
         const data = event.data;
         switch (data.status){

         case "result":
            
            this.sqlprocess[ data.id].results = data.results;
         break;
         case "finished":

            this.sqlprocess[ data.id].handler(

               this.sqlprocess[ data.id].results
            );
            delete this.sqlprocess[data.id];


         break;
         case "error":
      
            this.errorLog(data.error); 
            delete this.sqlprocess[data.id];
         break;
         } 
      });
      port1.start();

      (window as any) .Sqlite = {
         worker :  (window as any).SqliteWorker ,
         exec : ( sql:string , handler = ()=>{  }  )=>{ 
       
           let id;
           try{
             id =(window as any).randomUUID() 
           }catch (e){
             id = this.count ++;
           }
           this.sqlprocess[id] = { results:[] ,handler: handler};
           port1.postMessage({sql: sql, id: id } );
       
         }
       }
       
     this._worker = (window as any ).Sqlite;
     
     ( window as any ).Sql = this;
  }
      
  errorLog(e:any){

     console.warn("[Sqlite warn]:", e); 
  }

  async init (){

     await new Promise(s => {
           
       this.execute("CREATE TABLE IF NOT EXISTS aggregate_1hour ( sensed_at DATETIME, datetime integer, median_data1  real , median_data2  real ,median_data3  real , median_cdata  real , median_battery real , avg_data1  real , avg_data2  real ,avg_data3  real ,avg_cdata  real , avg_battery real , max_data1  real , max_data2  real ,max_data3  real ,max_cdata  real , max_battery real ,min_data1  real , min_data2  real , min_data3  real , min_cdata  real , min_battery real ,sensor_id varchar,sensor_type varchar ,PRIMARY KEY(sensor_id,sensed_at,sensor_type));", 
       (res:any)=>{
           this.execute(" create index  IF NOT EXISTS index_aggregate_1hour_datetime on aggregate_1hour(datetime);", 
           (res:any)=>{

              this.execute(" create index  IF NOT EXISTS index_aggregate_1hour_sensed_at on aggregate_1hour(sensed_at);", 
              (res:any)=>{

                 s(true);
              });
           });
        });  
     });
  
     await new Promise(s => {
    
        this.execute("CREATE TABLE IF NOT EXISTS aggregate_10min ( sensed_at DATETIME , datetime integer, median_data1  real , median_data2  real ,median_data3  real , median_cdata  real , median_battery real , avg_data1  real , avg_data2  real ,avg_data3  real ,avg_cdata  real , avg_battery real , max_data1  real , max_data2  real ,max_data3  real ,max_cdata  real , max_battery real ,min_data1  real , min_data2  real , min_data3  real , min_cdata  real , min_battery real ,sensor_type varchar ,sensor_id varchar ,PRIMARY KEY(sensor_id,sensed_at,sensor_type) );", 
        (res:any)=>{
           this.execute(" create index  IF NOT EXISTS index_aggregate_10min_datetime on aggregate_10min(datetime);", 
           (res:any)=>{

              this.execute(" create index  IF NOT EXISTS index_aggregate_10min_sensed_at on aggregate_10min(sensed_at);", 
              (res:any)=>{

                 s(true);
              });
           });
        });
     });
   
       await new Promise(s => {
    
       this.execute("CREATE TABLE IF NOT EXISTS aggregate_5min ( sensed_at DATETIME , datetime integer, median_data1  real , median_data2  real ,median_data3  real , median_cdata  real , median_battery real , avg_data1  real , avg_data2  real ,avg_data3  real ,avg_cdata  real , avg_battery real , max_data1  real , max_data2  real ,max_data3  real ,max_cdata  real , max_battery real ,min_data1  real , min_data2  real , min_data3  real , min_cdata  real , min_battery real ,sensor_type varchar , sensor_id varchar ,PRIMARY KEY(sensor_id,sensed_at,sensor_type) );", 
       (res:any)=>{
           this.execute(" create index  IF NOT EXISTS index_aggregate_5min_datetime on aggregate_5min(datetime);", 
           (res:any)=>{

              this.execute(" create index  IF NOT EXISTS index_aggregate_5min_sensed_at on aggregate_5min(sensed_at);", 
              (res:any)=>{

                 s(true);
              });
           });
        });  
     });
       
     await new Promise(s => {
    
       this.execute("CREATE TABLE IF NOT EXISTS aggregate_1min ( sensed_at , datetime integer, median_data1  real , median_data2  real ,median_data3  real , median_cdata  real , median_battery real , avg_data1  real , avg_data2  real ,avg_data3  real ,avg_cdata  real , avg_battery real , max_data1  real , max_data2  real ,max_data3  real ,max_cdata  real , max_battery real ,min_data1  real , min_data2  real , min_data3  real , min_cdata  real , min_battery real ,sensor_type varchar ,sensor_id varchar ,PRIMARY KEY(sensor_id,sensed_at,sensor_type) );", 
       (res:any)=>{
           this.execute(" create index  IF NOT EXISTS index_aggregate_1min_datetime on aggregate_1min(datetime);", 
           (res:any)=>{

              this.execute(" create index  IF NOT EXISTS index_aggregate_1min_sensed_at on aggregate_1min(sensed_at);", 
              (res:any)=>{

                 s(true);
              });
           });
        });  
     });
   
     await new Promise(s => {
    
         this.execute("CREATE TABLE IF NOT EXISTS first_1min ( sensed_at DATETIME, datetime integer, data1  varchar , data2  varchar , data3  varchar , cdata  varchar , battery real  , alive varchar ,sensor_type varchar ,sensor_id varchar ,PRIMARY KEY(sensor_id,sensed_at,sensor_type) );", 
         (res:any)=>{
           this.execute(" create index IF NOT EXISTS index_first_1min_datetime on first_1min(datetime);", 
           (res:any)=>{

              this.execute(" create index IF NOT EXISTS index_first_1min_sensed_at on first_1min(sensed_at);", 
              (res:any)=>{
  
                 s(true);
              });
           });
        });  
     });
      
     await new Promise(s => {
    
        this.execute("CREATE TABLE IF NOT EXISTS first_5min ( sensed_at DATETIME , datetime integer, data1  varchar , data2  varchar , data3  varchar , cdata  varchar , battery real , alive varchar ,sensor_type varchar ,sensor_id varchar ,PRIMARY KEY(sensor_id,sensed_at,sensor_type) );", 
         (res:any)=>{
           this.execute(" create index IF NOT EXISTS index_first_5min_datetime on first_5min(datetime);", 
           (res:any)=>{

              this.execute(" create index IF NOT EXISTS index_first_5min_sensed_at on first_5min(sensed_at);", 
              (res:any)=>{
  
                 s(true);
              });
           });
        });  
     });
      
     await new Promise(s => {
    
        this.execute("CREATE TABLE IF NOT EXISTS first_10min ( sensed_at DATETIME , datetime integer, data1  varchar , data2  varchar , data3  varchar , cdata  varchar , battery real  , alive varchar,sensor_type varchar ,sensor_id varchar ,PRIMARY KEY(sensor_id,sensed_at,sensor_type) );", 
        (res:any)=>{
           this.execute(" create index IF NOT EXISTS index_first_10min_datetime on first_10min(datetime);", 
           (res:any)=>{

              this.execute(" create index IF NOT EXISTS index_first_10min_sensed_at on first_10min(sensed_at);", 
              (res:any)=>{
  
                 s(true);
              });
           });
        });  
     });
     
     await new Promise(s => {     
        this.execute("CREATE TABLE IF NOT EXISTS first_1hour ( sensed_at DATETIME , datetime integer, data1  varchar , data2  varchar , data3  varchar , cdata  varchar , battery real  , alive varchar ,sensor_type varchar ,sensor_id varchar ,PRIMARY KEY(sensor_id,sensed_at,sensor_type) );", 
        (res:any)=>{
           
           this.execute(" create index IF NOT EXISTS index_first_1hour_datetime on first_1hour(datetime);", 
           (res:any)=>{

              this.execute(" create index IF NOT EXISTS index_first_1hour_sensed_at on first_1hour(sensed_at);", 
              (res:any)=>{
  
                 s(true);
              });
           });
        });  
     });
     
     await new Promise(s => {     
     
        this.execute("CREATE TABLE IF NOT EXISTS raw_data ( sensed_at DATETIME , datetime integer, data1  varchar , data2  varchar , data3  varchar , cdata  varchar , battery real  , alive varchar ,sensor_type varchar ,sensor_id varchar ,PRIMARY KEY(sensor_id,sensed_at,sensor_type ) );", 
        
        (res:any)=>{
           this.execute(" create index IF NOT EXISTS index_raw_data_datetime on raw_data(datetime);", 
           (res:any)=>{

              this.execute(" create index IF NOT EXISTS index_raw_data_sensed_at on raw_data(sensed_at);", 
              (res:any)=>{
  
                 s(true);
              });
           });
        });  
     });
     await new Promise(s => {     
     
        this.execute("CREATE TABLE IF NOT EXISTS latest_data ( sensed_at DATETIME , datetime integer, data1  varchar , data2  varchar , data3  varchar , cdata  varchar , battery real  , alive varchar ,sensor_type varchar ,sensor_id varchar ,PRIMARY KEY(sensor_id,sensor_type ) );", 
        
        (res:any)=>{
           s(true);
        });  
        
     });

     this.tableLodaded = true;
  }
     
  executeAsyncQueue( commands :any, func:any ) {
 
     this._commandQueue.push( { commands:commands , func : func });
  }
     
  async executeAsync () {
     try{
      
        this._isExecuting = true;
        
          
        for ( let c = this._commandQueue.shift( ); c ;  c = this._commandQueue.shift() ){
          
           await new Promise((s ,e ) => { 
            
            this.execute(c.commands , ( res:any )=> {
                  try{
                     
                     c.func(res);
                     s(true);
                            
                  } catch(er){
                    console.warn( er);
                    
                    e(er);  
           
                 }finally{ this._isExecuting = false;  }
              } );
           });
          
        }
         }catch (e){
              this._isExecuting = false;
         }
     }
     
  execute( commands :any, func:any ) {
        
     for (const key in this.valiables) {
        commands = commands.replaceAll( "{" + key + "}", this.valiables[key]); 
     }
     this._worker.exec(
      commands ,
      (  data: any ) =>{
     
         
            func( data);
           }
    );

  }


  dumpDB(func:any){
    /*
     this._worker.onmessage = function (event:any) {
        const arraybuff = event.data.buffer;
        func(arraybuff);
     };
     this._worker.postMessage({ action: 'export' });
  */
   }
  
  restoreDB(arraybuff:any,func:any ){
     /*
     this._worker.onmessage = (event:any)=> { func(event); }
     try {
        
        this._worker.postMessage({ action: 'open', buffer: arraybuff }, [arraybuff]);
     } catch (e:any) {
        //console.log(e);
        this._worker.postMessage({ action: 'open', buffer: arraybuff });
     }
        */
  }
}

/*
export class Sql  {

    private _worker?:any ;
    public tableLodaded? : boolean;
    public _commandQueue ? : any =[];
    private _isExecuting? : boolean =false;
    public valiables? :any ={};
    constructor() {
    	
    	if(( window as any ).Sql){

    		return ( window as any ).Sql;
    	}


    	this.tableLodaded = false;
    	const pathname= location.pathname;
  		const appPath = "/"+ pathname.split('/')[1];

      this._worker = new Worker( appPath+'/js/worker.sql-wasm.js');
      this._worker.postMessage({ action: 'open' });

      this._worker.onerror = this.errorLog;

      ( window as any ).Sql = this;
   }
       
   errorLog(e:any){

      console.warn("[Sqlite warn]:", e); 
   } 
   async init (){

      
      await new Promise(s => {
            
        this.execute("CREATE TABLE aggregate_1hour ( sensed_at DATETIME, datetime integer, median_data1  real , median_data2  real ,median_data3  real , median_cdata  real , median_battery real , avg_data1  real , avg_data2  real ,avg_data3  real ,avg_cdata  real , avg_battery real , max_data1  real , max_data2  real ,max_data3  real ,max_cdata  real , max_battery real ,min_data1  real , min_data2  real , min_data3  real , min_cdata  real , min_battery real ,sensor_id varchar,sensor_type varchar ,PRIMARY KEY(sensor_id,sensed_at,sensor_type));", 
        (res:any)=>{
            this.execute(" create index index_aggregate_1hour_datetime on aggregate_1hour(datetime);", 
            (res:any)=>{

               this.execute(" create index index_aggregate_1hour_sensed_at on aggregate_1hour(sensed_at);", 
               (res:any)=>{

                  s(true);
               });
            });
         });  
      });
   
      await new Promise(s => {
     
         this.execute("CREATE TABLE aggregate_10min ( sensed_at DATETIME , datetime integer, median_data1  real , median_data2  real ,median_data3  real , median_cdata  real , median_battery real , avg_data1  real , avg_data2  real ,avg_data3  real ,avg_cdata  real , avg_battery real , max_data1  real , max_data2  real ,max_data3  real ,max_cdata  real , max_battery real ,min_data1  real , min_data2  real , min_data3  real , min_cdata  real , min_battery real ,sensor_type varchar ,sensor_id varchar ,PRIMARY KEY(sensor_id,sensed_at,sensor_type) );", 
         (res:any)=>{
            this.execute(" create index index_aggregate_10min_datetime on aggregate_10min(datetime);", 
            (res:any)=>{

               this.execute(" create index index_aggregate_10min_sensed_at on aggregate_10min(sensed_at);", 
               (res:any)=>{

                  s(true);
               });
            });
         });
      });
    
        await new Promise(s => {
     
        this.execute("CREATE TABLE aggregate_5min ( sensed_at DATETIME , datetime integer, median_data1  real , median_data2  real ,median_data3  real , median_cdata  real , median_battery real , avg_data1  real , avg_data2  real ,avg_data3  real ,avg_cdata  real , avg_battery real , max_data1  real , max_data2  real ,max_data3  real ,max_cdata  real , max_battery real ,min_data1  real , min_data2  real , min_data3  real , min_cdata  real , min_battery real ,sensor_type varchar , sensor_id varchar ,PRIMARY KEY(sensor_id,sensed_at,sensor_type) );", 
        (res:any)=>{
            this.execute(" create index index_aggregate_5min_datetime on aggregate_5min(datetime);", 
            (res:any)=>{

               this.execute(" create index index_aggregate_5min_sensed_at on aggregate_5min(sensed_at);", 
               (res:any)=>{

                  s(true);
               });
            });
         });  
      });
        
      await new Promise(s => {
     
        this.execute("CREATE TABLE aggregate_1min ( sensed_at , datetime integer, median_data1  real , median_data2  real ,median_data3  real , median_cdata  real , median_battery real , avg_data1  real , avg_data2  real ,avg_data3  real ,avg_cdata  real , avg_battery real , max_data1  real , max_data2  real ,max_data3  real ,max_cdata  real , max_battery real ,min_data1  real , min_data2  real , min_data3  real , min_cdata  real , min_battery real ,sensor_type varchar ,sensor_id varchar ,PRIMARY KEY(sensor_id,sensed_at,sensor_type) );", 
        (res:any)=>{
            this.execute(" create index index_aggregate_1min_datetime on aggregate_1min(datetime);", 
            (res:any)=>{

               this.execute(" create index index_aggregate_1min_sensed_at on aggregate_1min(sensed_at);", 
               (res:any)=>{

                  s(true);
               });
            });
         });  
      });
    
      await new Promise(s => {
     
          this.execute("CREATE TABLE first_1min ( sensed_at DATETIME, datetime integer, data1  varchar , data2  varchar , data3  varchar , cdata  varchar , battery real  , alive varchar ,sensor_type varchar ,sensor_id varchar ,PRIMARY KEY(sensor_id,sensed_at,sensor_type) );", 
          (res:any)=>{
            this.execute(" create index index_first_1min_datetime on first_1min(datetime);", 
            (res:any)=>{

               this.execute(" create index index_first_1min_sensed_at on first_1min(sensed_at);", 
               (res:any)=>{
   
                  s(true);
               });
            });
         });  
      });
       
      await new Promise(s => {
     
         this.execute("CREATE TABLE first_5min ( sensed_at DATETIME , datetime integer, data1  varchar , data2  varchar , data3  varchar , cdata  varchar , battery real , alive varchar ,sensor_type varchar ,sensor_id varchar ,PRIMARY KEY(sensor_id,sensed_at,sensor_type) );", 
          (res:any)=>{
            this.execute(" create index index_first_5min_datetime on first_5min(datetime);", 
            (res:any)=>{

               this.execute(" create index index_first_5min_sensed_at on first_5min(sensed_at);", 
               (res:any)=>{
   
                  s(true);
               });
            });
         });  
      });
       
      await new Promise(s => {
     
         this.execute("CREATE TABLE first_10min ( sensed_at DATETIME , datetime integer, data1  varchar , data2  varchar , data3  varchar , cdata  varchar , battery real  , alive varchar,sensor_type varchar ,sensor_id varchar ,PRIMARY KEY(sensor_id,sensed_at,sensor_type) );", 
         (res:any)=>{
            this.execute(" create index index_first_10min_datetime on first_10min(datetime);", 
            (res:any)=>{

               this.execute(" create index index_first_10min_sensed_at on first_10min(sensed_at);", 
               (res:any)=>{
   
                  s(true);
               });
            });
         });  
      });
      
      await new Promise(s => {     
         this.execute("CREATE TABLE first_1hour ( sensed_at DATETIME , datetime integer, data1  varchar , data2  varchar , data3  varchar , cdata  varchar , battery real  , alive varchar ,sensor_type varchar ,sensor_id varchar ,PRIMARY KEY(sensor_id,sensed_at,sensor_type) );", 
         (res:any)=>{
            
            this.execute(" create index index_first_1hour_datetime on first_1hour(datetime);", 
            (res:any)=>{

               this.execute(" create index index_first_1hour_sensed_at on first_1hour(sensed_at);", 
               (res:any)=>{
   
                  s(true);
               });
            });
         });  
      });
      
      await new Promise(s => {     
      
         this.execute("CREATE TABLE raw_data ( sensed_at DATETIME , datetime integer, data1  varchar , data2  varchar , data3  varchar , cdata  varchar , battery real  , alive varchar ,sensor_type varchar ,sensor_id varchar ,PRIMARY KEY(sensor_id,sensed_at,sensor_type ) );", 
         
         (res:any)=>{
            this.execute(" create index index_raw_data_datetime on raw_data(datetime);", 
            (res:any)=>{

               this.execute(" create index index_raw_data_sensed_at on raw_data(sensed_at);", 
               (res:any)=>{
   
                  s(true);
               });
            });
         });  
      });
      await new Promise(s => {     
      
         this.execute("CREATE TABLE latest_data ( sensed_at DATETIME , datetime integer, data1  varchar , data2  varchar , data3  varchar , cdata  varchar , battery real  , alive varchar ,sensor_type varchar ,sensor_id varchar ,PRIMARY KEY(sensor_id,sensed_at,sensor_type ) );", 
         
         (res:any)=>{
            s(true);
         });  
         
      });

      this.tableLodaded = true;
   }
      
   executeAsyncQueue( commands :any, func:any ) {
      
      this._commandQueue.push( { commands:commands , func : func });
   }
      
   async  executeAsync () {
      try{
         
         await  new Promise((s)=>{
            
            const interval = setInterval(()=>{
            if(!this._isExecuting) {
               
               clearInterval (interval); s(true); }      
               },50);
         });
    
         this._isExecuting = true;
         
         for ( let c = this._commandQueue.shift( ); c ;  c = this._commandQueue.shift() ){
            
            try{
            await new Promise(s => { 
         
               this.execute(c.commands , ( res:any )=> {
                  try{
                     c.func(res);  s(true);
                             
                  } catch(er){
                     console.warn( er);
                    
                  }
               } );
            });
               } catch(er){
               console.warn( er);
               
               }
         }

           
          this._isExecuting = false;
          }catch (e){
               this._isExecuting = false;
          }
      }
      
   execute( commands :any, func:any ) {
         
      this._worker.onmessage = (event:any)=> {
         
         const results = event.data.results;
         if (!results) {  if(event.data.error){ 
            
            //  console.log(commands);
            
            this.errorLog(event.data.error); } }
         func(results);
		}
      
      for (const key in this.valiables) {
         commands = commands.replaceAll( "{" + key + "}", this.valiables[key]); 
      }
      
      this._worker.postMessage({ action: 'exec', sql: commands });   
	}

   onerror (func:any){

      this._worker.onerror = (e:any)=>{
         
         func(e);
      };

   }

	dumpDB(func:any){
	
      this._worker.onmessage = function (event:any) {
			const arraybuff = event.data.buffer;
			func(arraybuff);
		};
		this._worker.postMessage({ action: 'export' });
	}
   
   restoreDB(arraybuff:any,func:any ){
      
      this._worker.onmessage = (event:any)=> { func(event); }
      try {
			
         this._worker.postMessage({ action: 'open', buffer: arraybuff }, [arraybuff]);
		} catch (e:any) {
			//console.log(e);
			this._worker.postMessage({ action: 'open', buffer: arraybuff });
		}
   }
}
   */