import Dexie from 'dexie';
 
// IndexedDB の初期化
const db = new Dexie('db');
db.version(3).stores({ 
    'sensorDataCache':'url, data',
    'fileCache':'url, base64'  ,
    'potree' : 'url, arrayBuffer, readAt'
}).upgrade( (trans:any)=> {
   (db as any ).sensorDataCache.clear().then(()=> {
   
       }).catch((error:any)=> {
       console.log(error);
       });
    });

export { db } ;