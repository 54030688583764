const PCDViewer = () => import("../components/PCDViewer.vue");
const PointCloudProjects = () => import("../PointCloudProjects.vue");
const PointCloudFolders = () => import("../PointCloudFolders.vue");

export default {
  routes: [
  {
      path: "/pointcloud/projects/",
      name : "PointCloudProjects",
      component: PointCloudProjects
  },
  {
      path: "/pointcloud/projects/:projectname",
      name : "PointCloudFolders",
      component: PointCloudFolders
  }
 ,{
      path: "/pointcloud/pcdviewer",
      name : "PCDViewer",
      component: PCDViewer
  }
  ] } 

