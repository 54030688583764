import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vShow as _vShow, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"background-color":"rgb(32,33,35)","width":"100%","height":"20px","cursor":"pointer"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle([_ctx.windowCss, {"left":"0","background-color":"rgb(86,88,105)","color":"snow","position":"fixed","bottom":"0%","z-index":"99999999"}]),
    class: _normalizeClass(_ctx.showChat + _ctx.windowClass)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", {
        style: {"color":"green","font-size":"22px","position":"absolute","left":"10px","line-height":"22px"},
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.expansionWindow && _ctx.expansionWindow(...args)))
      }, " ● "),
      _createElementVNode("span", {
        style: {"color":"orange","font-size":"22px","position":"absolute","left":"30px","line-height":"22px"},
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeWindow && _ctx.closeWindow(...args)))
      }, " ● ")
    ]),
    _withDirectives(_createElementVNode("div", {
      class: "col-12 scroll_bar",
      style: _normalizeStyle([{"text-align":"left","overflow":"auto"}, _ctx.messagesScrollCss]),
      ref: "comments"
    }, [
      _createElementVNode("iframe", {
        class: _normalizeClass([_ctx.show, "col-12"]),
        allow: "cross-origin-isolated",
        src: "/c2w/?net=browser",
        style: {"height":"100%"}
      }, null, 2)
    ], 4), [
      [_vShow, _ctx.showContents]
    ])
  ], 6)), [
    [_vShow, 'hide'!==_ctx.showChat ]
  ])
}