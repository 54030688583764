
import { defineComponent } from "vue";
import { faPaperPlane, faMicrophone} from "@fortawesome/free-solid-svg-icons";
export default defineComponent({
props: {
    },
    data() { return {
        isLoaded : true ,
        messages:[],
        windowClass : " offset-0 col-12",
        messagesCss : { "height":"50vh"},
        messagesScrollCss : { "height":"78%"},
        windowCss : {} ,
        showContents : true, 
        voiceInputCss : {} ,
        userMessage:{role:"user",content:""},
          faPaperPlane :faPaperPlane,
          faMicrophone : faMicrophone ,
          voiceInputStarted : false ,
      showChat : "",
      show : "",
    recognition : undefined
    } },
     async  beforeMount  (this:any){        
    
     if(window.parent !== window && !(window as any ).isCrossOrigin ){  
          return;
        }
       
        try{ 
            
            const sr:any = ( window as  any ).webkitSpeechRecognition ||  ( window  as any ).SpeechRecognition;
            this.recognition = new sr();
            this.recognition.lang = 'ja-JP';
            this.recognition.interimResults = true;
            this.recognition.continuous = true;
            this.recognition.onresult = (event:any) => {
             let interimTranscript = ''; // 暫定(灰色)の認識結果
            for (let i = event.resultIndex; i < event.results.length; i++) {
                 let transcript :any = event.results[i][0].transcript;
            if (event.results[i].isFinal) {
                     this.userMessage.content += transcript;
                     
                }
            }
   } 
   }catch(e){ console.log(e);}
       
       
    },
      methods: {
          voiceInput(this:any){
              if ( this.voiceInputStarted){
                  this. recognition.stop();
                  this.voiceInputStarted =false;
                  this.voiceInputCss= {};
                  return;
              }
              this.voiceInputStarted = true;
               this.voiceInputCss= {"color":"yellowgreen"};
             this.recognition.start();
          },
    closeWindow (this:any){
      
        this.windowClass=" offset-0 col-12 ";   
        this.windowCss={ };
        this.messagesCss = {  "height":"50vh"};
        this.messagesScrollCss = { "height":"78%"};    
       this. showContents = !this.showContents;
       

    },
      expansionWindow(this:any){
          
          
          if ( this.windowClass ===" col-12" ){
                
                this.windowClass=" offset-0 col-12 ";   
                this.windowCss={ };
                this.messagesCss = {  "height":"50vh"};
                this.messagesScrollCss = { "height":"78%"};
          } else {
              
                this.windowClass =" col-12"  ;
                this.windowCss={ "left":0 , "height":"100vh", "top": 0  };
                this.messagesCss = { "height":"80vh"};
                this.messagesScrollCss = { "height":"100%"};
                this.show = "";  
          }
          
          const elms:any =  ( window as any ).document.getElementsByClassName("iframe_graph");
        // var cdt = new Date().getTime();

          for (  const i in elms ){
              
              try{ 
                const src = elms[ i ].src;
                
                elms[ i ].src =  src +"&c";
              }catch (e:any) { console.log(e);}
          }
          
      },
    closeChat(){
        
    } ,
    openChat ( this:any ){
        
       if ( this.show === "hide" ){
           
           this.showChat="fadeUp";
           this.show="" ;
       }else { 
       this.windowClass="offset-0 col-12 ";   
                    this.windowCss={ };
                    this.messagesCss = {  "height":"50vh"};
                    this.messagesScrollCss = { "height":"78%"};
          this.show ="hide";
           this.showChat="";
        } 
    }
    }
    
    });
