<template>
<div>
<link rel="stylesheet"  :href="css" />

<div v-show="alertMessages.length" style="position:absolute; width: 100%; left: 0; z-index: 99999999;">
  <div v-for="(message,i) in alertMessages"  style="margin: 1%; text-align: center;background-color: rgba(240, 62, 60, 0.8) ; color: white; line-height: 40px;width: 100%;" v-bind:key="i">
       <b 
    v-on:click="removeAlert(message)"
   style="background-color: rgba(20,20,20,0.3);
    display: block;
    width: 100%;
    top: px;
    margin-top: -3px;
    font-size: 16px;
    text-align: left;
    line-height: 16px;
    padding: 3px;
    cursor: pointer;
    font-weight: bolder;" > x </b>    

  <div style="width:100%; font-size:12px; line-height: 30px;" v-html="message"> </div>
  
    </div>
  </div>
  
<div v-show="!isLoaded" id="loadingview" style="
    width: 100%;
    height: 100vh;
    display: inline-block;
    position: fixed;
    z-index: 99999;
    left: 0;
    top: 0;
    background: white;">
    
    <div class="spinner-box">
        <div class="pulse-container">  
            <div class="pulse-bubble pulse-bubble-1"></div>
            <div class="pulse-bubble pulse-bubble-2"></div>
            <div class="pulse-bubble pulse-bubble-3"></div>
        </div>
    </div>
</div>

   <header v-show="!disableHeader" style="display:none; position:fixed; z-index:9999999; width: 100%;" class="header navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">

  <a class="collapsed" :onClick="showMenu" aria-expanded="false" aria-label="Toggle navigation" style="cursor: pointer;"> 
    <font-awesome-icon :icon="faTachometerAlt" style="margin-left:8px;"  size="lg" />
  </a>
 
 <span v-show="!prefetch.done"  style="color:darkgray;">
   バックグラウンドでデータを読み込み中
 </span>
 
  <a class="navbar-brand col-md-3 col-lg-2 me-0 px-3" href="#">PaletteIoT</a>
  
       <span v-show="!isOnline" class="text-warning" > オフラインです</span>
   
  <ul class="navbar-nav px-3">
    
    <li v-show=" !isSharedAccess"  class="nav-item text-nowrap">
      
        <a v-show="isLoggedin" style="cursor:pointer;" @click="logout"> サインアウト</a>
        <a v-show="!isLoggedin" style="cursor:pointer;"  class="text-warning"  @click="login"> ログインしてください</a>
    </li>

  </ul>
</header>
<div class="container-fluid">
  <div class="row">
      <nav id="sidebarMenu" style="z-index:99999; position: fixed; height: 100%;"  class="col-md-3 col-lg-2 bg-light sidebar collapse d-none">
      <div class="position-sticky pt-3">
        <br>
        <ul class="nav flex-column">
          <li class="nav-item">
          </li>

          <li class="nav-item" v-for=" (item, index) in menu" :key="index" >
          <router-link v-on:click="showMenu" :to="{ path : item.url }"> {{ item.name }}</router-link>
          </li>
         
         <li class="nav-item" v-for=" (item, index) in custommenu" :key="index" >
    
          <a v-on:click="showMenu" v-if="item.path.indexOf('#') !== -1 "  :href="jumpTo(item.path)">  {{ item["menu-title"] }}  </a>
          <router-link v-on:click="showMenu" v-else :to="{ path : item.path }"> {{ item["menu-title"] }}</router-link> 
    
          </li>
        
          <li class="nav-item">
             <router-link v-on:click="showMenu" :to="{ path : '/settings' }"> 設定 </router-link> <br>
          </li>
         
         </ul>
   
      </div>
    </nav>

      <main  v-if="dataLoaded" ref="main" style="margin-top:45px;" class="col-md-12 ms-sm-auto col-lg-12 px-md-4">
         <router-view @fetch-custom-data="fetchCustomData" @fetch-data="fetchData" @show-loader="showLoader" @show-flash="showFlash" @show-menu="showMenu" @show-header="showHeader" 
         @refresh-if-not-loaded="refreshIfNotLoaded" @add-menu="addMenu" @show-edit-css="showEditCSS"
          />
  
    <Flash :message ="flashmessage" :classes ="flashclasses" />

    <GPTChat @fetch-data="fetchData"  @show-loader="showLoader" @show-flash="showFlash" />
 
    <EditCSS v-if="editCSSParams.show"  @remove-css="removeCSS" @show-flash="showFlash" :params="editCSSParams" />

    <Console v-if="useC2W" @fetch-data="fetchData"  @show-loader="showLoader" @show-flash="showFlash" />

    <pre style="text-align:left;" v-show="showLogContainer" id="logContainer">

      <pre v-for="(log, index) in logs" :key="index" :class="log.type">
        [{{ log.type.toUpperCase() }}] {{ log.message }}
      </pre>
    </pre>

         </main>

    </div>
    </div>
</div>
</template>

<script type="ts">

window.Compression = require("./modules/app/compression.js");

import moment from "moment";

import { fetchAndloadToSqlite3Exec,setFetchAndloadToSqlite3,setFetchRawDataAndloadToSqlite3 ,setFetchFirstDataAndloadToSqlite3, setFetchAggregateDataAndloadToSqlite3 } from "./modules/webapi/sensordata";

import { faTachometerAlt } from "@fortawesome/free-solid-svg-icons";
import { defineComponent } from "vue";
// const Flash = () => import( "@/components/Flash.vue" )
import Flash from "@/components/Flash.vue"; 
import { Sql } from "./modules/app/sql";

import EditCSS from "@/components/edit/CSS.vue";

import GPTChat from "@/components/openai/GPTChat.vue"; 

import Console from "@/components/c2w/Console.vue"; 
import { Observer } from "./modules/app/observer";

window.DateSelectorObserver = new Observer();

try{ 
    if ( window.document.domain.includes("palettelabs.net") ){
  
    window.document.domain = "palettelabs.net";
    }
}catch (e){ console.log(e) ; }
    

window.getSql = async ()=>{
      return new Promise((s)=>{
          const interval = setInterval(()=>{
            if(window.Sql && window.Sql.tableLodaded) { clearInterval (interval); 
   
                    s(window.Sql); 
             }      
            },100);
        })
  }

export default defineComponent({
 
  name: "App",
   
  data(){ return {
    logs : [] ,
      css : `data:text/css;base64,${Buffer.from( "" , 'utf-8').toString('base64')}`,

      editCSSParams:{show:false },
      isLoggedin : false , 
      isOnline : true ,  
      dataLoaded : false ,
      showLogContainer : false , 
       /*getSql : async ()=>{ 
        
        return  new Promise( (resolve) => {
           const interval =  setInterval(() => { 
             if (this.sqlTablesLoaded.num == this.sqlTablesLoaded.tables.length ) { 
              resolve(this.Sql); clearInterval(interval); } } ,  2000) ; } );
      },*/
      isSharedAccess : false ,
      useC2W : false ,
      dashboard :{ css:"", menu:[]},
      prefetch : {done: true}  ,
      alertMessages : [] ,
      port :   (window.location.port)? ":"+window.location.port : "" ,
      isLoaded : false ,
      disableHeader : true,
      isShowMenu : false,
      faTachometerAlt: faTachometerAlt,
      originalUrl : "",
      showChat : false ,
      "menu" :[ 
     /*   { "name":"Wordpress", url : ""},
        { "name":"Kibana", url : ""},*/
       ],
       custommenu : [],
      "flashmessage":""  , flashclasses: " d-none "
  } },
    beforeCreate : async function (){
 
    if(this.dataLoaded){

      return; 
  
    }
 
    if(window.parent === window ||
          window.isCrossOrigin ){ 

          const sql = new Sql();
          await sql.init();
          window.Sql =sql;      
    } 

    if( window != window.parent && 
          !window.isCrossOrigin
        ){ 
// iframe で読み込まれている場合 
       this.dataLoaded = true;
        }
   
   },

mounted : function(){

  const loadingScreen = document.getElementById('loading-screen');
  if (loadingScreen) {
    loadingScreen.style.display = 'none';
  }
},
created : async function (){

      const urlParams = new URLSearchParams(window.location.search);
      
      if (urlParams.get('debug') === 'log-Shie') {
        
        
        this.showLogContainer = true;

       const tmpConsole = window.console;

        const appendMessageToLogContainer = (type, args) => {
          const message = args.map(arg => typeof arg === 'object' ? JSON.stringify(arg) : arg).join(' ');
          this.logs.push({ type, message });
        };
       // const tmpConsole = window.console ;
        // Override console.log
        
          window.console.log = (...args) => {
          appendMessageToLogContainer('log', args);
         // tmpConsole.log( args); // Optional: Keep original functionality
        };

        // Override console.warn
        window.console.warn = (...args) => {
          appendMessageToLogContainer('warn', args);
         // tmpConsole.warn( args); // Optional: Keep original functionality
        };

        // Override console.error
        window.console.error = (...args) => {
          appendMessageToLogContainer('error', args);
         // tmpConsole.error( args); // Optional: Keep original functionality
        };  
       }


        this . isSharedAccess = (window.sharedAccessKey )? true :false ;
        this.originalUrl  = window.location.href;
        var param = new URLSearchParams(window.location.search);
        
        if( param.get("login") === '') {
            window.location.href = window.location.protocol+'//'+ window.location.hostname +this.port +'/app/loggedin';
        }
         
        if(   window.location.pathname === "/app/" && param.get("loggedout")==="" ){

            window.location.href= window.location.href +"/?";  
        }
        
        if(   window.location.pathname === "/pwa/" && param.get("loggedout")==="" ){
           window.location.href= window.location.href +"/?";  
        }
            
        if(   window.location.pathname === "/app/" && param.get("loggedin")==="1" ){
            setTimeout(()=>{  window.location.href= window.location.href.replace('loggedin=1','loggedin=');  } , 1000);
        }
          
        if( window.location.pathname === "/pwa/" && param.get("loggedin")==="1" ){
            setTimeout(()=>{  window.location.href= window.location.href.replace('loggedin=1','loggedin=');  } , 1000);
        }
        
          if( window.location.pathname === "/app/" && param.get("loggedin")==="" ){
            window.location.href= window.location.href+"/?";  
          }else
         if(  window.location.pathname === "/pwa/" && param.get("loggedin")==="" ){
                  window.location.href= window.location.href+"/?";  
          } else
          
          if( window.self !== window.top ){
   
           if ( window.location.href.match(/#/)){
              
              this.dataLoaded = true;
            }   
            }

            try{          // ログインチェック   
                
                
                fetch(   window.location.protocol+"//"+ window.location.hostname +this.port +"/ok/")
                    .then(response => {
                
                        if(response.status == 200){
                            
                            this.isLoggedin = true;

                        } else{
                    
                            this.isLoggedin = false;
                        }
                        return response; 
                    }).then(data =>{ }).then(e =>{ 
                      
                      
                      if(e){ this.isLoggedin = false; } 
                      
                      });
            
                        } catch(e){ console.log(e); this.isLoggedin = false; }
        
          this.isOnline = window.navigator.onLine;
            
          this.isLoaded = true;
        
          const oparateHash = ()=>{
            

          const path = window.location.href.split('#');
            
          if ( 1 < path.length  ){
          
            if( window.location.href.indexOf('close') === -1 ){
                  
                    this.$router.push(path[1].replace('/pwa',''));
                }
            }
          }

          //  oparateHash();
   
            window.addEventListener('hashchange', ()=>{
              oparateHash();

            if( window != window.parent && 
                !window.isCrossOrigin
            
            ){ 
                return ;
            }  
        }, false);

 

        if( window != window.parent && 
          !window.isCrossOrigin
        ){ 
// iframe で読み込まれている場合 
            const path = window.location.href.split('#');
             if ( 1 < path.length  ){
             //   const p = path[1];

              
              this.$router.push(path[1].replace('/pwa','') );
            }
  
          return ;
        }
     
  var d ={};

      if ( this.isOnline ){ 
   
    const se =await fetch( process.env.VUE_APP_APP_API_V4_URL + "dashboard/setting" );

   if (se.ok){
 d = await se.json();
   } else {   this.isLoggedin = false; }
  
      } else  {
       
       try { 
               d= JSON.parse( localStorage.getItem("d.dashboards" ) ); 
            } catch (e) { console.log(e); }
        }

      if (  d.dashboards && d.dashboards.length ){
         
            if ( d.dashboards ) {
                try {  localStorage.setItem("d.dashboards" , JSON.stringify( d ) ); } catch (e) { console.log(e); }
            }
            this.Sql = await   window.getSql();
                
            try{
              if (d.dashboards){
               if(d.dashboards.length){
                if(d.dashboards[0]){
                    this.dashboard = d.dashboards[0];
                    this.css = `data:text/css;base64,${Buffer.from( this.dashboard.css , 'utf-8').toString('base64')}`;
                  }
                }
              } 
            }catch (e ){ console.error(e); }

            await this.fetchData (  {  prefetch : this.dashboard.prefetch , done : ()=>{} } );

             for(let i=0;i<this.dashboard.menu.length;i++){
                 
                this.custommenu.push (this.dashboard.menu[i]);
             } 


        if (window.parent === window){
          if (this.dashboard.usec2w){
            this.useC2W = true ;
          }
        }

         }
         else {
                    this.isLoggedin = false;
             }

             
                this.dataLoaded = true;
                 
  },

watch:{
      async dataLoaded (newValue) {
    if(!newValue){return; }
    if ( window.location.pathname == "/app" || window.location.pathname == "/pwa" 
                || window.location.pathname == "/app/" || window.location.pathname == "/pwa/"  ) {
                        if( !(window.location.href.indexOf('#')  !== -1) ){
                            if(this.dashboard.firstview){
                         
                         this.$router.push(this.dashboard.firstview);    
                            }  

                
                            if( this.dashboard.messageurl ){
                               await this. showAlertByUrl( this.dashboard.messageurl );
                            }
                       }else {

                                 const path = window.location.href.split('#');
             if ( 1 < path.length  ){
             //   const p = path[1];
                this.$router.push(path[1].replace('/pwa',''));
            }
                       }      
              }
    }
  },

  updated(){

      const path = window.location.href.split('@');
     if ( 1 < path.length  ){
     
        this.$router.push(path[1]);
     } 
  },
  methods: {
    removeCSS(){

      this.css = `data:text/css;base64,${Buffer.from( "" , 'utf-8').toString('base64')}`;
    },
    showEditCSS ( param ){
      if (param.path == "*"){
           this.css = `data:text/css;base64,${Buffer.from( "" , 'utf-8').toString('base64')}`;
      }
      this.editCSSParams = param;
    },

     async fetchCustomData(param){

    for (var i=0;i< param.settings.length ; i++){
   
  try{
    const settings = param.settings[i];

    if( !settings  ){ continue ; }

     const success = { "flash" :{  "message": "データを読み込みました。   描画には時間がかかる場合がございます。" ,"type": "success" }} ;
     
       this.Sql = await (window.parent.parent ).getSql();  
       

       var  startDate = moment().format('yyyy-MM-DD') ;
      var  endDate= moment().add('d',1).format('yyyy-MM-DD');
    

      if ( settings.startDate ){
          startDate = settings.startDate;
       }


      if ( settings.vstarthourname && settings.vstarthourname  != "" ){
           this.Sql.valiables[settings.vstarthourname] = settings.starthour;
       }


       if ( settings.needEnd ){
        
                  
            if ( settings.vendhourname && settings.vendhourname != "" ){
               this.Sql.valiables[settings.vendhourname] = settings.endhour;
             }

            if (settings.endDate){
            endDate = settings.endDate;
            }
            if ( this.endDate){
                 end = moment(this.endDate);
            }
            
            if ( settings.vendname!=""){
                      

                this.Sql.valiables[settings.vendname] = endDate;
                 
            }
       }
      

       if (settings.vstartname!=""){
              
                this.Sql.valiables[settings.vstartname] = startDate;
              
        }


      var start = moment( startDate); 
      var end = moment(endDate);


       if( 1 >end.diff(start)){
         
                return   ;
                // { "flash" : {"message":"検索する日付をご確認して下さい。","type": "warning"}} ;   
       }
       

        const ids = Array.isArray( settings.sensorids) ? settings.sensorids : settings.sensorids.split(",") ;
        
        this.isLoaded = false;

        this.beforeStartDate =start;
        this.beforeEndDate =end;
        this.beforeSelectedAggregate = this.selectedAggregate;
     
         
        for (var j=0 ; j <  ids.length ;  j ++ ){
     
     //  await new Promise(s => setTimeout(s, 100)); 
          // if(!start ){continue;}
          // if(!end ){continue;}
   
          const node_id= ids[j];
        if(node_id ==""){ continue ;}
        
        if (settings.needRawData ){

          await  setFetchAndloadToSqlite3 (  
	        node_id ,
	        start  ,
	        end  ,
	        "",
	        false ,
	        false,
            true );
            //await fetchAndloadToSqlite3Exec();   
        }
        if ( settings.needFirstData || settings.needAggregateData ){
            

            if(settings.granularity1min){
           await setFetchAndloadToSqlite3 (  
	        node_id ,
	        start  ,
	        end  ,
	        "1min",
	        settings.needFirstData   ,
	        settings.needAggregateData ,
	        false );
           //await fetchAndloadToSqlite3Exec();
            }

            if(settings.granularity10min){
              
           await setFetchAndloadToSqlite3 (  
	        node_id ,
	        start  ,
	        end  ,
	        "10min",
	        settings.needFirstData   ,
	        settings.needAggregateData ,
	        false );
        
      //  await fetchAndloadToSqlite3Exec();
            }

            if(settings.granularity1hour){
           await setFetchAndloadToSqlite3 (  
	        node_id ,
	        start  ,
	        end  ,
	        "1hour",
	        settings.needFirstData   ,
	        settings.needAggregateData ,
	        false);
         //   
                    }
          }
        }
  
        }catch (e){ console.error(e); }
  }
   this.showLoader(true);
   await fetchAndloadToSqlite3Exec();
 await param.done();
  this.showLoader(false);

     },
      async fetchData (  d  /* { prefetch ,  suc ()=>{} } */ ) {
           try {
             this.Sql = await   window.getSql();
            
              if ( d.prefetch){
                this.prefetch = Object.assign({}, d.prefetch );
                
                  var  requestNum  =  this.prefetch.sensor.granularity.length *  this.prefetch.sensor.sensors.length ; 
                  var start, end; 
                  if (this.prefetch.sensor){
                    
                            if (typeof this.prefetch.sensor.periodofday === "string" || this.prefetch.sensor.periodofday instanceof String) {
                                    const temp = this.prefetch.sensor.periodofday.split(',') ;
                                    start = moment(temp[0]);
                                    end = moment(temp[1]);
                                
                            } else {
                             end = moment(new Date ()).add('1','day');
                             start = end.clone().add( -1*this.prefetch.sensor.periodofday ,'day');
                            }
              
                            for (var i=0 ;  i < this.prefetch.sensor.granularity.length ; i++ ){
                                
                                -- requestNum;
                                let granularity =  this.prefetch.sensor.granularity[i];
                                for (var j=0 ;  j < this.prefetch.sensor.sensors.length ; j++ ){
                              
                                    if (granularity.includes("aggregate_")){
                                         granularity = granularity.replace("_","=").replace("aggregate","agg" );
                                    }
                                    if (granularity.includes("first_")){
                                        granularity =  granularity.replace("_","=");
                                    }
                                    
                                    const node_id = this.prefetch.sensor.sensors[j]; 
                                    const g = granularity.split("=");
                                  
                                    if (g[0]=="first"){
                                          
                                          await setFetchFirstDataAndloadToSqlite3(
                                          node_id ,
	                                        start  ,
	                                        end  ,
	                                        g[1]
                                          );
                                    }else if (g[0]=="agg") {
                                          await setFetchAggregateDataAndloadToSqlite3(
                                          node_id ,
	                                        start  ,
	                                        end  ,
	                                        g[1]
                                          );
                                    }else{
                                        await setFetchRawDataAndloadToSqlite3(
                                          node_id ,
	                                        start  ,
	                                        end  
                                          );
                                    }
                                    
                                  if (this.prefetch.sensor.async){
                              
                                    this.showLoader(true);
                                    this.prefetch.done = true;
                                    await fetchAndloadToSqlite3Exec();
                                    -- requestNum;
                                    if( 0 >= requestNum ){
                                      d.done();  
                                      this.showLoader(false);
                                    }
                                  
                                  } else {
                                 
                                    this.prefetch.done = false ;    
                                    fetchAndloadToSqlite3Exec().then( ()=>{

                                      -- requestNum;
                                      if( 0 >= requestNum ){
                                        d.done();
                                        this.prefetch.done = true;    
                                       
                                      }
                                      
                                  } ); 
                                  
                                 }    
                               
                                }
                            }
                       }
                  }  
                  
        } catch (e){ 
          this.prefetch.done = true ;
          d.done();

          this.showLoader(false);
        }   
          
      },
     
      async showAlertByUrl( url ){
        try{
              
            const data= await (await fetch( url ) ) .json();
            if(  data ){

                Object.keys(data).forEach( (key)=> {
                    for ( var i = 0 ; i < data[key].messages.length ; i ++ ){ this.alertMessages.push ( data[key].messages[i] ); }
                });
            }  
        }catch  ( e ) {
            console.log( e );
        }
    },
    removeAlert( mes  ){
        
        var arr =[];
        for ( var m = this.alertMessages.shift(); m ; m = this.alertMessages.shift()){
              if ( m !== mes ){
                  arr.push(m);
              }
         }
          this.alertMessages = arr ;
        
      },
    login(){
      if (window == window.parent ){
      window.location.href = window.location.protocol+'//' + window.location.hostname +this.port + '/pwa/?login=';
      } else {
        const url=location.href;
        const reg=new RegExp("//.*?/(.*?)(?=/)");
        const path = url.match(reg)[1];
    
       const w = window.open(window.location.protocol+'//' + window.location.hostname +this.port + '/'+path+'/?login=');
        const timer = setInterval(() => {
        if(!w.closed) return;
        clearInterval(timer);
        
           setTimeout(()=>{
  
                window.location.href = window.location.protocol+'//' + window.location.hostname +this.port + '/'+path+'/?loggedin=1' ;
                console.log('window closed');
            } , 2000);
     
        }, 1500);  
      }
    },
      jumpTo : function(url){
          return "/"+location.pathname.split('/')[1] + url ;
      },
      showHeader: function(){
          
          this.disableHeader = false;   
      },
         showMenu: function(){
        const menu = window.document.getElementById("sidebarMenu");
        if(this.isShowMenu){
        menu.className = "col-md-3 col-lg-2 bg-light sidebar collapse show";
        this.isShowMenu = false;
        } else{
            this.disableHeader=false;
            
               menu.className = "col-md-3 col-lg-2 bg-light sidebar collapse  d-none ";
     
            this.isShowMenu = true;
        }
      },

      logout : function(){
          window.location.href = window.location.protocol+'//' + window.location.hostname  +this.port+'/logout?redirect=/pwa/';
        /*
         const apppath = (window.location.href.indexOf('/pwa/') !== -1 ) ? "/pwa" : "/app"
       
         const path = (window.location.href.indexOf('paletteiot.azurewebsites.net') !== -1 ) ?
         "/oauth" : "" ;
         
         const w = window.open( window.location.protocol+"//" + window.location.hostname  +this.port+ path +"/logout?redirect=/app/loggedin");
        const timer = setInterval(  () => {
        if(!w.closed) return;
        
        clearInterval(timer);
        setTimeout(()=>{
            
            window.location.href = window.location.protocol+'//' + window.location.hostname  +this.port+ apppath+'/?loggedout=';
            console.log('window closed');
        } , 2000);
        
        }, 1500);       
        */
                
      },
    showLoader: function(b ){

        this.isLoaded = !b;
    },
    refreshIfNotLoaded:function(){
      //  alert(this.isLoaded);
        if(!this.isLoaded){
           this.$router.go({path: this.$router.currentRoute.path, force: true});
  
        }
    },
     addMenu : function(o){
        
          this.dashboard.menu.push(o);
            this.custommenu.push (o);
      },
    showFlash: function(o) {
 
        this.flashmessage = o.message; 
        this.flashclasses =  " active effect-fadein alert-" + o.type ;
        
           setTimeout(function(){

            this.flashclasses =  "effect-fadeout alert-" + o.type  ;
             setTimeout(function(){
                this.flashmessage =  "" ;
                this.flashclasses =  "";
            }.bind(this),500);
        }.bind(this), 3000) ;
        
     }
  },
  components:  { Flash , GPTChat, Console ,EditCSS }
});
</script>

<style lang="scss">

  /*jsからのPWA判定の為 必須*/
@media (display-mode: standalone){
}

@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}

@keyframes fadeout {
    from {
        opacity:1;
    }
    to {
        opacity:0;
    }
}
.effect-fadeout {
animation-name: fadeout;
    animation-duration: 1s;
}

.effect-fadein {
animation-name: fadein;
    animation-duration: 1s;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
main {
  height: 100vh;
}

h1,h2, h3 , h4 , li{
    margin: 0.5em !important;
}

a {
    font-weight: bold;
    color: dimgray !important;
    text-decoration: none!important;
}
#nav { padding: 30px; }


.form-check-input {
  clear: left;
}

.form-switch.form-switch-sm {
  margin-bottom: 0.5rem; /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-sm .form-check-input {
  height: 1rem;
  width: calc(1rem + 0.75rem);
  border-radius: 2rem;
}

.form-switch.form-switch-md {
  margin-bottom: 1rem; /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-md .form-check-input {
  height: 1.5rem;
  width: calc(2rem + 0.75rem);
  border-radius: 3rem;
}

.form-switch.form-switch-lg {
  margin-bottom: 1.5rem; /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-lg .form-check-input {
  height: 2rem;
  width: calc(3rem + 0.75rem);
  border-radius: 4rem;
}

.form-switch.form-switch-xl {
  margin-bottom: 2rem; /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-xl .form-check-input {
  height: 2.5rem;
  width: calc(4rem + 0.75rem);
  border-radius: 5rem;
}



@keyframes pulse {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: .25;
    transform: scale(.75);
  }
}


.spinner-box {

  margin-left: auto;
  margin-right: auto;
  margin-top: 12%;
  width: 20%;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

/* PULSE BUBBLES */

.pulse-container {
  width: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pulse-bubble {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #3ff9dc;
}

.pulse-bubble-1 {
    animation: pulse .4s ease 0s infinite alternate;
}
.pulse-bubble-2 {
    animation: pulse .4s ease .2s infinite alternate;
}
.pulse-bubble-3 {
    animation: pulse .4s ease .4s infinite alternate;
}

#logContainer {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      max-height: 200px;
      overflow-y: auto;
      background-color: rgba(0, 0, 0, 0.8);
      color: white;
      font-family: monospace;
      padding: 10px;
      box-sizing: border-box;
      z-index: 1000; /* Ensure the log container is on top */
    }
    .log {
      color: #00FF00;
    }
    .warn {
      color: #FFFF00;
    }
    .error {
      color: #FF0000;
    }


</style>